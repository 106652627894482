import React, { FC } from 'react';
import { ThemeProvider as InternalThemeProvider } from 'styled-components';
import { useConfig } from '../config';
import { themes } from '../theme';

const ThemeProvider: FC = ({ children }) => {
  const { code } = useConfig();
  return (
    <InternalThemeProvider theme={themes[code]}>
      {children}
    </InternalThemeProvider>
  );
};

export default ThemeProvider;
