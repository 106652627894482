import styled from 'styled-components';
import {
  fontSize,
  FontSizeProps,
  color,
  ColorProps,
  fontWeight,
  FontWeightProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  fontStyle,
  FontStyleProps,
} from 'styled-system';

type Props =
  | FontSizeProps
  | ColorProps
  | FontWeightProps
  | SpaceProps
  | TextAlignProps
  | FontStyleProps;

const Text = styled.span<Props>`
  ${space}
  ${fontSize}
  ${color}
  ${fontWeight}
  ${textAlign}
  ${fontStyle}

  line-height: 20px;
`;

Text.defaultProps = {
  fontSize: 2,
};

export default Text;
