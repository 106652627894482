import React, { FC } from 'react';
import { Button, Dialog, Heading, Link, Spacer, Text } from '../../components';
import { Trans } from '@lingui/macro';
import { useConfig } from '../../config';

type ErrorCodeSwitchProps = {
  errorCode: string;
  userEmail: string;
};

const ErrorCodeSwitch: FC<ErrorCodeSwitchProps> = ({
  errorCode,
  userEmail,
}) => {
  if (errorCode === 'E02')
    return (
      <Trans
        render={({ translation }) => <Text as="p">{translation}</Text>}
        id={'errorCode.E02'}
      >
        The email address{' '}
        <Text fontStyle="italic" fontWeight={1}>
          {userEmail}
        </Text>{' '}
        is already used as user ID and cannot be saved. Please use a different
        email address.
      </Trans>
    );

  return (
    <Trans
      render={({ translation }) => <Text as="p">{translation}</Text>}
      id={'errorCode.E02'}
    >
      The email address{' '}
      <Text fontStyle="italic" fontWeight={1}>
        {userEmail}
      </Text>{' '}
      is already used as user ID and cannot be saved. Please use a different
      email address.
    </Trans>
  );
};

const ErrorCodeDialog: FC<{
  onClose: () => void;
  userEmail: string;
  errorCode: string;
}> = ({ onClose, userEmail, errorCode }) => {
  const { email, phone, phoneValue } = useConfig();

  return (
    <Dialog onClose={onClose}>
      <Trans
        render={({ translation }) => (
          <Heading color="primary">{translation}</Heading>
        )}
        id="Error code dialog title"
      >
        Registration was not successful!
      </Trans>

      <ErrorCodeSwitch errorCode={errorCode} userEmail={userEmail} />
      <Trans
        render={({ translation }) => <Text as="p">{translation}</Text>}
        id="Error code dialog body"
      >
        Please try again or contact our subscription service (Phone{' '}
        <Link href={`tel:${phoneValue}`}>{phone}</Link> or email{' '}
        <Link href={`mailto:${email}`}>{email}</Link>
        ).
      </Trans>
      <Spacer />
      <Button mt={true} variant="outline" onClick={onClose} type="button">
        <Trans>Close message</Trans>
      </Button>
    </Dialog>
  );
};

export default ErrorCodeDialog;
