import styled from 'styled-components';
import {
  fontSize,
  FontSizeProps,
  color,
  ColorProps,
  fontWeight,
  FontWeightProps,
} from 'styled-system';

type Props = FontSizeProps | ColorProps | FontWeightProps;

const Heading = styled.h1<Props>`
  ${fontSize}
  ${color}
  ${fontWeight}

  letter-spacing: -0.35px;
`;

Heading.defaultProps = {
  fontSize: 4,
  fontWeight: 1,
};

export default Heading;
