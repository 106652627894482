import React, { ReactNode, FC } from 'react';
import { Text } from '../Text';
import { Root, Label } from './styles';

type Props = {
  htmlFor: string;
  hint?: ReactNode;
  text: ReactNode;
};

const Field: FC<Props> = ({ text, hint, children, htmlFor }) => {
  return (
    <Root>
      <Label htmlFor={htmlFor}>
        <Text fontWeight={1}>{text}</Text>
        {hint && (
          <Text fontSize={0} ml="1rem">
            {hint}
          </Text>
        )}
      </Label>
      {children}
    </Root>
  );
};

export default Field;
