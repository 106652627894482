import React, { forwardRef, ReactNode } from 'react';
import { Root, Input as InternalInput } from './styles';

type Ref = HTMLInputElement;
type Props = React.HTMLProps<HTMLInputElement> & {
  rightAttachment?: ReactNode;
};

const Input = forwardRef<Ref, Props>(
  ({ rightAttachment, id, ...props }, ref) => {
    return (
      <Root>
        <InternalInput id={id} ref={ref} {...(props as any)} />
        {rightAttachment && <>{rightAttachment}</>}
      </Root>
    );
  }
);

export default Input;
