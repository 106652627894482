import styled from 'styled-components';

const ValidationError = styled.p`
  color: red;

  &::before {
	display: inline;
  	content: "⚠ ";
  }
`;

export default ValidationError;
