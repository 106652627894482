import styled, { css } from 'styled-components';
import { system } from '../../theme';

type Variant = 'submit' | 'outline';

type Props = {
  variant: Variant;
  mt?: boolean;
  mts?: boolean;
};

const Button = styled.button<Props>`
  font-family: ${system.fontFamily()};
  border: none;
  text-align: center;
  color: white;
  border-radius: ${system.radii(1)};
  height: 36px;
  max-height: 36px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.variant === 'submit' &&
    css`
      letter-spacing: -0.23px;
      margin-top: 1.2rem;
      padding-bottom: 0.6rem;
      padding-top: 0.6rem;
      font-size: ${system.fontSizes(3)};
      font-weight: ${system.fontWeights(1)};
      flex: 1;
      border-style: solid;
      border-width: 1px;
      border-color: ${system.colors('button.border')};
      background-color: ${system.colors('button.background')};
      color: ${system.colors('button.color')};
    `}

  ${(props) =>
    props.variant === 'outline' &&
    css`
      letter-spacing: -0.2px;
      text-align: center;
      padding: 0.7rem;
      font-weight: ${system.fontWeights(1)};
      font-size: ${system.fontSizes(3)};
      border-style: solid;
      border-width: 2px;
      border-color: ${system.colors('primary')};
      background-color: transparent;
      color: ${system.colors('primary')};

      &:hover {
        cursor: pointer;
      }
    `}

    ${(props) =>
    props.mt &&
    css`
      margin-top: 3rem;
    `}

    ${(props) =>
    props.mts &&
    css`
      margin-top: 1rem;
    `}
`;

export default Button;
