import { useLingui } from '@lingui/react';
import React from 'react';
import { Box } from '../../components';
import { activate, LOCALES } from '../../i18n';
import { publisher, useConfig, useLogoPosition } from '../../config';
import { useSelect } from 'downshift';
import { LocaleSwitchRoot } from './styles';
import ArrowDown from '../../assets/keyboard_arrow_down.svg';
import { useTheme } from 'styled-components';
import { Logo } from '../../publisher';

const LocaleSwitch = () => {
  const { locales } = useConfig();
  const { i18n } = useLingui();
  const theme: any = useTheme();

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: locales,
    initialSelectedItem: i18n.locale,
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        activate(changes.selectedItem, publisher);
      }
    },
  });

  return (
    <LocaleSwitchRoot>
      <button type="button" {...getToggleButtonProps()}>
        {i18n._(LOCALES[selectedItem!!])}
        <img alt="arrow down" src={ArrowDown} />
      </button>
      <ul {...getMenuProps()}
      	style={
			 isOpen
			 	? { borderStyle: theme.borders.localeSwitch.borderstyle }
			 	: { borderStyle: 'none' }
		  }>
        {isOpen &&
          locales.map((item, index) => (
            <li
              style={
                highlightedIndex === index
                  ? { backgroundColor: theme.colors.secondary }
                  : {}
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {i18n._(LOCALES[item])}
            </li>
          ))}
      </ul>
    </LocaleSwitchRoot>
  );
};

const TopSection = () => {
  const { locales } = useConfig();
  const isLocaleSwitcherShown = locales.length > 1;
  const position = useLogoPosition();

  return (
    <Box
      style={{
        justifyContent: position,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Logo />
      {isLocaleSwitcherShown && <LocaleSwitch />}
    </Box>
  );
};

export default TopSection;
