import styled from 'styled-components';
import { system } from '../../theme';

const Link = styled.a`
  color: ${system.colors('primary')};
  display: inline-block;

  a:hover {
    cursor: pointer;
  }
`;

export default Link;
