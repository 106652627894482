import styled from 'styled-components';

const Overlay = styled.div`
  background-color: rgba(245, 245, 245, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
`;

export default Overlay;
