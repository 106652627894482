import React, { FC } from 'react';
import { Button, Dialog, Spacer, Text } from '../../components';
import { Trans } from '@lingui/react';

const InfoCompanyDialog: FC<{ onClose: () => void }> = ({ onClose }) => {
	return (
		<Dialog onClose={onClose}>
			<Trans
				render={({ translation }) => <Text as="p">{translation}</Text>}
				id="Info company dialog body"
				components={{
					0: <br />,
					1: <Text as="p" />,
				}}
				message="
				If the subscription is for your company, enter the company name in the «Name/Company» field.
				If it is a personal subscription, enter your first name in the «First name» field and your surname in the «Name/Company» field.
				"
			/>
			<Spacer />
			<Button mts={true} variant="outline" onClick={onClose} type="button">
				<Trans id="Close message" message="Close message" />
			</Button>
		</Dialog>
	);
};

export default InfoCompanyDialog;
