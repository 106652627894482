import styled from 'styled-components';

export const Root = styled.div`
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  align-items: left;

  input {
    flex: 1;
  }

  margin-top: 1rem;
`;

export const Label = styled.label`
  text-align: left;
  line-height: 20px;
`;
