import { setupI18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { publisher, defaultLanguage, configs } from './config';

export const getDefaultLanguage = () => {
  // Publisher supports only single language
  if (configs[publisher].locales.length === 1)
    return configs[publisher].locales[0];
  // Default provided via cookie, choosen only if publisher supports it
  if (defaultLanguage) {
    if (configs[publisher].locales.includes(defaultLanguage)) {
      return defaultLanguage;
    }
  }

  // Detect language provided by browser, choosen only in case when publisher supports it
  const language = window.navigator.language;
  if (language) {
    const locale = language.substr(0, 2);
    if (configs[publisher].locales.includes(locale)) {
      return locale;
    }
  }

  console.log('fallback', configs[publisher]);
  // If none of expected conditions match fallback to first element
  // Shouldn't happen with proper configuration
  // Assumption is made that publisher supports at least single language
  return configs[publisher].locales[0];
};

export const instance = setupI18n();

export const LOCALES: { [key: string]: string } = {
  en: t`English`,
  it: t`Italian`,
  fr: t`French`,
  de: t`German`,
};

export async function activate(locale: string, publisher: string) {
  const { messages } = await import(
    /* webpackChunkName: "i18n-[index]" */ `@lingui/loader!./locales/${publisher}/${locale}.po`
  );

  instance.load(locale, messages);
  instance.activate(locale);
}
