import React, { FC, useState, useCallback, useEffect, Fragment } from 'react';
import { Layout, Link, Box, Text, Heading, Button } from '../../components';
import {
  HeaderRoot,
  PrivacySectionRoot,
  InfoSectionRoot,
  TextButton,
} from './styles';
import { Notification } from '../../model';
import Form, { FormValues } from './Form';
import { Result } from '../../model';
import SuccessDialog from './SuccessDialog';
import LoadingOverlay from './LoadingOverlay';
import RejectDialog from './RejectDialog';
import ErrorDialog from './ErrorDialog';
import ExampleDialog from './ExampleDialog';
import ErrorCodeDialog from './ErrorCodeDialog';
import TopSection from './TopSection';
import InfoCompanyDialog from './InfoCompanyDialog';
import { isLogoLegacy, useConfig } from '../../config';
import { Trans } from '@lingui/react';
import { Logo } from '../../publisher';

const NotificationSwitch: FC<{
  result: Result;
  errorCode: string | undefined;
  onClose: () => void;
  form: FormValues | undefined;
}> = ({ result, onClose, form, errorCode }) => {
  const email = form?.email ?? '';

  switch (result) {
    case 'IDLE':
      return null;
    case 'SUCCESS':
      return <SuccessDialog onClose={onClose} userEmail={email} />;
    case 'PENDING':
      return <LoadingOverlay />;
    case 'ERROR':
    case 'TIMEOUT':
      return <ErrorDialog onClose={onClose} />;
    case 'REJECT':
      return <RejectDialog onClose={onClose} />;
    case 'ERROR_CODE':
      return (
        <ErrorCodeDialog
          onClose={onClose}
          userEmail={email}
          // For now fallback to only existing error code(E02), if in future we'll have more
          // we should create message for code of unknown type
          errorCode={errorCode ?? 'E02'}
        />
      );
  }
};

const Footer = () => {
  const {
    email,
    phone,
    phoneValue,
    links: { subscriptionVariants },
  } = useConfig();
  return (
    <Box as="footer">
      <Trans
        render={({ translation }) => (
          <Text as="p" fontSize={1} m={0}>
            {translation}
          </Text>
        )}
        values={{
          phoneValue,
          email,
          phone,
        }}
        components={{
          0: (
            <Link href={subscriptionVariants} target="_blank" rel="noopener">
              «Subscription Variants»
            </Link>
          ),
          1: <Link href={`tel:${phoneValue}`}>{phone}</Link>,
          2: <Link href={`mailto:${email}`}>{email}</Link>,
          3: <br />,
        }}
        id="Footer body"
        message="
          If you do not have a subscription you will find here our different
          <0/>. If you
          do not have received an email for a new password or have further
          questions please contact our subscription service (Phone
          <1/> or email <2/>).
        "
      />
    </Box>
  );
};

const Header = () => {
  const config = useConfig();
  const hasLegacyLogo = isLogoLegacy(config);

  return (
    <HeaderRoot>
      <Trans
        render={({ translation }) => <Heading>{translation}</Heading>}
        id="Registration view title"
        message="Registration"
      />
      {hasLegacyLogo && <Logo />}
    </HeaderRoot>
  );
};

const InfoSection: FC<{ onOpenExampleDialog: () => void }> = ({
  onOpenExampleDialog,
}) => {
  const {
    fullName,
    email,
    links: { webpage },
  } = useConfig();
  return (
    <InfoSectionRoot>
      <Trans
        id="Information section body"
        values={{
          fullName,
        }}
        components={{
          0: (
            <TextButton onClick={onOpenExampleDialog}>
              «Customer No.»
            </TextButton>
          ),
          1: <Link href={`mailto:${email}`}>{email}</Link>,
          2: <p />,
          3: (
            <Link href={webpage} target="_blank" rel="noopener">
              {webpage}
            </Link>
          ),
        }}
        message="
        As a subscriber, you are entitled to receive the digital edition of
        {fullName}. A one-time registration is necessary for this. The necessary
        <0/> you will find on your subscription invoice.
        "
      />
    </InfoSectionRoot>
  );
};

const PrivacySection = () => {
  const {
    links: { privacy },
  } = useConfig();
  return (
    <PrivacySectionRoot>
      <Trans
        render={({ translation }) => <Text as="p">{translation}</Text>}
        id="Privacy section body paragraph 1"
        message="Your data is in good hands with us!"
      />
      <Trans
        render={({ translation }) => <Text as="p">{translation}</Text>}
        id="Privacy section body paragraph 2"
        components={{
          0: (
            <Link href={privacy} target="_blank" rel="noopener">
              Privacy Policy
            </Link>
          ),
        }}
        message="With your registration you accept our <0/>"
      />
    </PrivacySectionRoot>
  );
};

const DevComponent = ({
  onStatusChange,
}: {
  onStatusChange: (value: Result) => void;
}) => {
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  return (
    <div
      style={{
        zIndex: 9999,
        padding: 24,
        position: 'fixed',
        right: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Button onClick={() => onStatusChange('IDLE')} variant="outline">
        IDLE
      </Button>
      <Button onClick={() => onStatusChange('ERROR')} variant="outline">
        ERROR
      </Button>
      <Button onClick={() => onStatusChange('ERROR_CODE')} variant="outline">
        ERROR_CODE
      </Button>
      <Button onClick={() => onStatusChange('PENDING')} variant="outline">
        PENDING
      </Button>
      <Button onClick={() => onStatusChange('REJECT')} variant="outline">
        REJECT
      </Button>
      <Button onClick={() => onStatusChange('SUCCESS')} variant="outline">
        SUCCESS
      </Button>
      <Button onClick={() => onStatusChange('TIMEOUT')} variant="outline">
        TIMEOUT
      </Button>
    </div>
  );
};

const Registration = () => {
  const config = useConfig();
  const [form, setForm] = useState<FormValues | undefined>();
  const [isExampleDialogOpen, setExampleDialogOpen] = useState(false);
  const [isInfoCompanyDialogOpen, setInfoCompanyDialogOpen] = useState(false);
  const [errorCode, setErrorCode] = useState<string>();
  const [status, setStatus] = useState<Result>('IDLE');
  const hasLegacyLogo = isLogoLegacy(config);

  const onClose = useCallback(() => {
    setForm(undefined);
    setErrorCode(undefined);
    setStatus('IDLE');
  }, []);

  const onSubmit = useCallback((data: FormValues) => {
    setForm(data);
  }, []);

  useEffect(() => {
    if (form && status === 'IDLE') {
      setStatus('PENDING');
      const params = encodeURI(
        `businessPartnerId=${form.businessPartnerId}&firstName=${encodeURIComponent(form.firstName)}&lastName=${form.lastName}&email=${form.email}&zipCode=${form.zipCode}`
      );

      const client = new EventSource(`/api/registration?${params}`);

      client.onmessage = (event) => {
        const message: MessageEvent = event as MessageEvent;
        const data: Notification = JSON.parse(message.data);
        if (
          data.status === 'SUCCESS' ||
          data.status === 'ERROR' ||
          data.status === 'ERROR_CODE' ||
          data.status === 'REJECT'
        ) {
          setStatus(data.status);
          if (data.status === 'ERROR_CODE') {
            setErrorCode(data.errorCode);
          }

          client.close();
        }
      };

      client.onerror = (err) => {
        setStatus('REJECT');
      };
    }
  }, [form, status]);

  return (
    <Fragment>
      <DevComponent onStatusChange={(value) => setStatus(value)} />
      <NotificationSwitch
        form={form}
        result={status}
        onClose={onClose}
        errorCode={errorCode}
      />
      {isExampleDialogOpen && (
        <ExampleDialog onClose={() => setExampleDialogOpen(false)} />
      )}
      {isInfoCompanyDialogOpen && (
        <InfoCompanyDialog onClose={() => setInfoCompanyDialogOpen(false)} />
      )}
      <Layout>
        {!hasLegacyLogo && <TopSection />}
        <Box>
          <Header />
          <InfoSection onOpenExampleDialog={() => setExampleDialogOpen(true)} />
          <Form
            onSubmit={onSubmit}
            onOpenExampleDialog={() => setExampleDialogOpen(true)}
            onOpenInfoCompanyDialog={() => setInfoCompanyDialogOpen(true)}
          />
          <PrivacySection />
        </Box>
        <Footer />
      </Layout>
    </Fragment>
  );
};

export default Registration;
