import React from 'react';
import BGSBSrc from '../assets/bgsb/img/example.png';
import MUQSrc from '../assets/muq/img/example.png';
import ORGASrc from '../assets/orga/img/example.png';
import ALIMSrc from '../assets/alim/img/example.jpg';
import TREXSrc from '../assets/trex/img/example.png';
import GRTSrc from '../assets/grt/img/example.png';
import MUKSrc from '../assets/muk/img/example.png';
import ORNSrc from '../assets/orn/img/example.png';
import GRWOSrc from '../assets/grwo/img/example.png';
import SUPSrc from '../assets/sup/img/example.png';
import CIGSrc from '../assets/cig/img/example.png';
import DEFAULTSrc from '../assets/default/img/example.png';
import { PublisherName, useConfig } from '../config';

const lookup: { [key in PublisherName]: string } = {
  default: DEFAULTSrc,
  bgsb: BGSBSrc,
  muq: MUQSrc,
  muk: MUKSrc,
  wewo: DEFAULTSrc,
  alim: ALIMSrc,
  orga: ORGASrc,
  trex: TREXSrc,
  grt: GRTSrc,
  orn: ORNSrc,
  grwo: GRWOSrc,
  sup: SUPSrc,
  cig: CIGSrc,
};

const BusinessPartnerExample = () => {
  const { code } = useConfig();

  return <img src={lookup[code]} alt="Business partner example" />;
};

export default BusinessPartnerExample;
