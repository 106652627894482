import styled from 'styled-components';
import { Overlay } from '../../components';
import { system } from '../../theme';

export const LoadingOverlayRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > b {
    padding-top: 1rem;
    display: flex;
    text-align: center;
  }
`;

export const FormRoot = styled.form`
  display: flex;
  flex-direction: column;

  & > div::not(${Overlay}) {
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    align-items: left;

    margin-top: 1rem;
  }
`;

export const HeaderRoot = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;

  h1 {
    margin: 0;
    padding: 0;
  }
`;

export const PrivacySectionRoot = styled.section`
  margin-top: 0.5rem;
  text-align: center;

  p {
    margin: 0;
  }
`;

export const InfoSectionRoot = styled.section`
  width: 100%;
`;

export const TextButton = styled.button`
  outline: none;
  border: none;
  background: none;
  padding: 0;
  color: ${system.colors('primary')};
  font-size: ${system.fontSizes(2)};
  text-decoration: ${system.borders('textButton.textdecoration')};

  &:hover {
    cursor: pointer;
  }
`;

export const LocaleSwitchRoot = styled.div`
  display: flex;
  position: relative;

  button {
    align-self: center;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    box-shadow: none;
    font-weight: ${system.fontWeights(1)};
    font-size: ${system.fontSizes(2)};
  }

  ul {
    margin-top: 28px;
    border-radius: ${system.borders('localeSwitch.borderradius')};
    max-height: calc(4 * 24px + 2 * 8px);
    max-width: 88px;
    width: 88px;
    overflow-y: auto;
    color: ${system.colors('localeSwitch.color')};
    font-weight: ${system.fontWeights(1)};
    background-color: ${system.colors('localeSwitch.background')};
    border-color: ${system.colors('localeSwitch.bordercolor')};
    border-style: ${system.borders('localeSwitch.borderstyle')};
    border-width: ${system.borders('localeSwitch.borderwidth')};
    padding: 0;
    list-style: none;
    position: absolute;

    li:first-child {
      margin-top: 8px;
    }

    li:last-child {
      margin-bottom: 8px;
    }

    & > li {
      padding-right: 8px;
      text-align: right;
      height: 24px;

      &:hover {
        cursor: default;
      }
    }
  }
`;

export const Centered = styled.div`
  display: flex;
  align-items: center;
`;
