import React, { FC } from 'react';
import { Button, Dialog, Heading, Link, Spacer, Text } from '../../components';
import { Trans } from '@lingui/react';
import { useConfig } from '../../config';

const ErrorDialog: FC<{ onClose: () => void }> = ({ onClose }) => {
  const {
    phone,
    email,
    phoneValue,
    links: { subscriptionVariants },
  } = useConfig();
  return (
    <Dialog onClose={onClose}>
      <Trans
        render={({ translation }) => (
          <Heading color="primary">{translation}</Heading>
        )}
        id="Error dialog title"
        message="Registration was not successful!"
      />
      <Trans
        render={({ translation }) => <Text>{translation}</Text>}
        values={{
          phone,
          email,
          phoneValue,
        }}
        id="Error dialog body"
        components={{
          0: <Link href={`tel:${phoneValue}`}>{phone}</Link>,
          1: <Link href={`mailto:${email}`}>{email}</Link>,
          2: <Heading color="primary" />,
          3: <Heading />,
          4: <Text fontWeight={700} />,
          5: <Text as="p" />,
          6: (
            <Link href={subscriptionVariants} target="_blank" rel="noopener" />
          ),
          7: <br />,
        }}
        message="
          We could not find your data in this combination in our system.
          Please try again or contact our subscription service (Phone <0/> or email <1/>).
        "
      />
      <Spacer />
      <Button mt={true} variant="outline" onClick={onClose} type="button">
        <Trans id="Close message" message="Close message"></Trans>
      </Button>
    </Dialog>
  );
};

export default ErrorDialog;
