import React, { FC, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Overlay } from '../Overlay';
import { Root, CloseIcon, Wrapper, Container } from './styles';

type Props = {
  onClose: () => void;
};

const Dialog: FC<Props> = ({ children, onClose }) => {
  const bodyRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const ref = bodyRef.current;
    if (ref) {
      disableBodyScroll(ref);
    }

    return () => {
      if (ref) {
        enableBodyScroll(ref);
      }
    };
  }, []);

  return (
    <Wrapper>
      <Overlay />
      <Container>
        <Root ref={bodyRef as any}>
          <CloseIcon onClick={onClose} />
          {children}
        </Root>
      </Container>
    </Wrapper>
  );
};

export default Dialog;
