import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Box } from '../Box';
import { system } from '../../theme';

export const Wrapper = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 1300;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  outline: 0;
  transform: none;

  h1 {
    padding-right: 32px;
  }
`;

export const Root = styled(Box)`
  box-sizing: inherit;
  margin: 0;
  border-radius: ${system.radii(1)};
  text-align: left;
  position: relative;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  img {
    border: solid 1px #979797;
    margin-top: 3rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  button {
    flex: 1;
    width: 100%;
  }

  h1 {
    margin-top: 0;
  }

  /* Specific styles for small mobile devices to support scrolling in dialog */
  @media (max-width: 576px) {
    width: 100%;
    height: var(--app-height);
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
  }

  @media (max-height: 440px) {
    width: 100%;
    height: var(--app-height);
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
  }
`;

export const CloseIcon = styled(FontAwesomeIcon).attrs((props) => ({
  size: '2x',
  icon: faTimesCircle,
}))`
  position: absolute;
  right: 1rem;
  color: ${system.colors('primary')};

  &:hover {
    cursor: pointer;
  }
`;
