import React, { FC } from 'react';
import { Dialog, Button, Link, Heading, Spacer } from '../../components';
import { Trans } from '@lingui/react';
import { useConfig } from '../../config';

const RejectDialog: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { email, phone, phoneValue } = useConfig();
  return (
    <Dialog onClose={onClose}>
      <Trans
        render={({ translation }) => (
          <Heading color="primary">{translation}</Heading>
        )}
        id="Reject dialog title"
        message="
          Unfortunately, your registration could not be completed successfully due 
          to a technical problem.
        "
      />
      <Trans
        id="Reject dialog body"
        values={{
          phoneValue,
          phone,
          email,
        }}
        components={{
          0: <Link href={`tel:${phoneValue}`}>{phone}</Link>,
          1: <Link href={`mailto:${email}`}>{email}</Link>,
          2: <p />,
          3: <br />,
        }}
        message="
          <2>Please contact our subscription service (Phone <0/> or email <1/>).</2>
          <2>We apologize for the inconvenience and we are grateful for Your understanding.</2>
        "
      />
      <Spacer />
      <Button mt={true} variant="outline" onClick={onClose} type="button">
        <Trans id="Close message" message="Close message" />
      </Button>
    </Dialog>
  );
};

export default RejectDialog;
