import { useLingui } from '@lingui/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { PublisherName, useConfig } from '../config';
import { defineMessage } from '@lingui/macro';

defineMessage({
  id: 'Website description',
  message: 'Registration',
});

const lookup: { [key in PublisherName]: string } = {
  default: 'asmiq_favicon_35x32.png',
  bgsb: 'bgsb-favicon-16x16.png',
  muq: 'favicon.ico',
  muk: 'asmiq_favicon_35x32.png',
  wewo: 'asmiq-favicon-32x32.png',
  orga: 'favicon.ico',
  alim: 'alim-favicon.png',
  trex: 'trex_favicon_16x16.png',
  grt: 'grt-favicon-32x32.png',
  orn: 'ornis_favicon.png',
  grwo: 'grwo-favicon-32x32.png',
  sup: 'sup-favicon-260x260.png',
  cig: 'cig-favicon-300x300.png',
};

const Head = () => {
  const { code } = useConfig();
  const { i18n } = useLingui();

  return (
    <Helmet>
      <link
        rel="icon"
        href={`${process.env.PUBLIC_URL}/${code}/${lookup[code]}`}
      />
      <meta name="description" content={i18n._('Website description')} />
    </Helmet>
  );
};

export default Head;
