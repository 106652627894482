import styled from 'styled-components';
import { system } from '../../theme';

const Root = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 256px;

  & > svg {
    position: absolute;
    right: 12px;
    pointer-events: none;
  }

  /* For some reason has to be separate, otherwise it breaks styling */
  & > select {
    font-size: ${system.fontSizes(3)};
  }

  & > select {
    width: 100%;
    height: 38px;
    padding: 8px;
    border: solid 1px #c8c8c8;
    border-radius: 2px;
    font-family: ${system.fontFamily()};
    background: none;
    appearance: none;

    & > option {
      font-size: ${system.fontSizes(3)};
      font-family: ${system.fontFamily()};
    }
  }
`;

export { Root };
