import styled from 'styled-components';

const Layout = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 100%;
  }
`;

export default Layout;
