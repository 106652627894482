import styled from 'styled-components';
import { system } from '../../theme';

export const Input = styled.input`
  font-size: ${system.fontSizes(3)};
  height: 38px;
  padding: 8px;
  border: solid 1px ${system.colors('input.border')};
  background: ${system.colors('input.background')};
  /* TODO radius less than 4px */
  border-radius: ${system.radii(0)};
  font-family: ${system.fontFamily()};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    border-color: ${system.colors('input.borderfocus')};
  }
`;

export const Root = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  div {
    position: absolute;
    right: 0;
    height: 100%;
    padding-right: 0.6rem;
    padding-left: 1rem;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    svg {
      color: ${system.colors('primary')};
    }
  }
`;
