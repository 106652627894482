import React, { FC } from 'react';
import { Button, Dialog, Spacer } from '../../components';
import { Trans } from '@lingui/macro';
import { BusinessPartnerExample } from '../../publisher';

const ExampleDialog: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Dialog onClose={onClose}>
      <BusinessPartnerExample />
      <Spacer />
      <Button variant="outline" onClick={onClose} type="button">
        <Trans>Close message</Trans>
      </Button>
    </Dialog>
  );
};

export default ExampleDialog;
