import React, { FC } from 'react';
import { Dialog, Button, Link, Heading, Text, Spacer } from '../../components';
import { Trans } from '@lingui/react';
import { useConfig } from '../../config';

const SuccessDialog: FC<{ onClose: () => void; userEmail?: string }> = ({
  onClose,
  userEmail = '',
}) => {
  const { phone, email, phoneValue, code } = useConfig();

  return (
    <Dialog onClose={onClose}>
      <Trans
        render={({ translation }) => (
          <Heading color="primary">{translation}</Heading>
        )}
        id="Success dialog title"
        message="Your registration was successful!"
      />
      <Trans
        id="Success dialog body"
        values={{
          userEmail,
          phone,
          phoneValue,
          email,
        }}
        components={{
          0: <i />,
          1: <br />,
          2: <b />,
          3: <Text fontStyle="italic" fontWeight={1} />,
          4: <Link href={`tel:${phoneValue}`}>{phone}</Link>,
          5: <Link href={`mailto:${email}`}>{email}</Link>,
          6: <Text as="p" />,
          7: (
            <Link
              target="_blank"
              rel="noopener"
              href={`https://login.asmiq.ch/auth/realms/${code}/login-actions/reset-credentials?client_id=${code}`}
            >
              «Forgot your password»
            </Link>
          ),
        }}
        message="
          <6>
            <0>New registration:</0>
            <1/>
            We will send <2>within the next 10 minutes</2> to your email address (<3>{userEmail}</3>) 
            a link to set a new password. Please consult your inbox.
            This link is valid for 24 hours.
          </6>
          <6>
            <0>Already registered:</0>
            <1/>
            If you do not receive an email within this period you are already
            registered and you can log in with your password.
          </6>
          <6>
            If you do not know your password, please use «Forgot your password».
          </6>
          <6>
            For problems or questions please contact our subscription service (Phone 
            <4/> or email <5/>
            ).
          </6>
        "
      />
      <Spacer />
      <Button mt={true} variant="outline" onClick={onClose} type="button">
        <Trans id="Close message" message="Close message" />
      </Button>
    </Dialog>
  );
};

export default SuccessDialog;
