import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Field, Input, ValidationError } from '../../components';
import { Centered, FormRoot } from './styles';
import { Trans } from '@lingui/macro';


export type FormValues = {
	firstName: string;
	lastName: string;
	email: string;
	businessPartnerId: string;
	zipCode: string;
};

const minBusinessPartnerId = 1;
const maxBusinessPartnerId = 9223372036854775807;
const invalidBusinessPartnerMessage =
	'Expected value in the range: ' +
	minBusinessPartnerId +
	' ... ' +
	maxBusinessPartnerId;

const Form: FC<{
	onSubmit: (data: FormValues) => void;
	onOpenExampleDialog: () => void;
	onOpenInfoCompanyDialog: () => void;
}> = ({ onSubmit, onOpenExampleDialog, onOpenInfoCompanyDialog }) => {
	const { register, formState: { errors }, handleSubmit } = useForm<FormValues>({
		mode: 'onBlur',
	});

	return (
		<FormRoot onSubmit={handleSubmit(onSubmit)}>
			<Field text={<Trans>First name</Trans>} htmlFor="firstName">
				<Input
					type="text"
					name="firstName"
					id="firstName"
					ref={register({ required: false, maxLength: 256 })}
				/>
			</Field>

			<Field text={<Trans>Last name / Company</Trans>} htmlFor="lastName">
				<Input
					type="text"
					name="lastName"
					id="lastName"
					rightAttachment={
						<Centered onClick={onOpenInfoCompanyDialog}>
							<FontAwesomeIcon icon={faQuestionCircle} />
						</Centered>
					}
					ref={register({ required: true, maxLength: 256 })}
				/>
				{errors.lastName?.type === "required" && (
					<ValidationError><Trans>«Name/Company» field is required.</Trans></ValidationError>
				)}
			</Field>

			<Field text={<Trans>Postcode</Trans>} htmlFor="zipCode">
				<Input
					type="text"
					name="zipCode"
					id="zipCode"
					ref={register({ required: true, minLength: 4, maxLength: 256 })}
				/>
				{errors.zipCode?.type === "required" && (
					<ValidationError><Trans>Zip code field is required.</Trans></ValidationError>
				)}
				{errors.zipCode?.type === "minLength" && (
					<ValidationError><Trans>Min. length of zip code field is 4 characters.</Trans></ValidationError>
				)}
			</Field>

			<Field
				text={<Trans>Customer no.</Trans>}
				hint={<Trans>Ex. 12345</Trans>}
				htmlFor="businessPartnerId"
			>
				<Input
					max={maxBusinessPartnerId}
					min={minBusinessPartnerId}
					name="businessPartnerId"
					type="number"
					id="businessPartnerId"
					rightAttachment={
						<Centered onClick={onOpenExampleDialog}>
							<FontAwesomeIcon icon={faQuestionCircle} />
						</Centered>
					}
					ref={register({
						required: true,
						max: {
							value: maxBusinessPartnerId,
							message: invalidBusinessPartnerMessage,
						},
						min: {
							value: minBusinessPartnerId,
							message: invalidBusinessPartnerMessage,
						},
					})}
				/>
				{errors.businessPartnerId?.type === "required" && (
					<ValidationError><Trans>Customer no. field is required.</Trans></ValidationError>
				)}
				{(errors.businessPartnerId?.type === "min" || errors.businessPartnerId?.type === "max") && (
					<ValidationError><Trans>Expected value in the range: 1 ... 9223372036854775807</Trans></ValidationError>
				)}
			</Field>

			<Field
				text={<Trans>Email address</Trans>}
				hint={<Trans>(your future user ID)</Trans>}
				htmlFor="email"
			>
				<Input
					type="email"
					name="email"
					id="email"
					ref={register({
						required: true,
						pattern: {
							value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
							message: "Invalid email address"
						}
					})}
				/>
				{errors.email?.type === "required" && (
					<ValidationError><Trans>Email field is required.</Trans></ValidationError>
				)}
				{errors.email?.type === "pattern" && (
					<ValidationError><Trans>Email address is invalid.</Trans></ValidationError>
				)}
			</Field>

			<Button variant="submit" type="submit">
				<Trans>Register</Trans>
			</Button>
		</FormRoot>
	);
};

export default Form;
