import { themeGet } from '@styled-system/theme-get';
import { Color } from './color';
import { Border } from './border';

export const fontSizes = (value: 0 | 1 | 2 | 3, format: 'px' = 'px') => (
  props: any
) => {
  return `${themeGet(`fontSizes.${value}`)(props)}${format}}`;
};

export const colors = (value: Color) => {
  return themeGet(`colors.${value}`);
};

export const borders = (value: Border) => {
  return themeGet(`borders.${value}`);
};

export const fontWeights = (value: 0 | 1 | 2) => {
  return themeGet(`fontWeights.${value}`);
};

export const radii = (value: 0 | 1, format: 'px' = 'px') => (props: any) => {
  return `${themeGet(`radii.${value}`)(props)}${format}}`;
};

export const fontFamily = () => {
  return themeGet('fonts.default');
};
