import React from 'react';
import { Text } from '../../components';
import { LoadingOverlayRoot } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Trans } from '@lingui/macro';

import styled from 'styled-components';

const Overlay = styled.div`
  background-color: rgba(245, 245, 245, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1300;
`;

const LoadingOverlay = () => (
  <Overlay>
    <LoadingOverlayRoot>
      <FontAwesomeIcon size={'4x'} icon={faCircleNotch} spin={true} />
      <Trans
        render={({ translation }) => <Text fontWeight={1}>{translation}</Text>}
      >
        Processing, please do not close the browser.
      </Trans>
    </LoadingOverlayRoot>
  </Overlay>
);

export default LoadingOverlay;
