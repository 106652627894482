import React, { forwardRef } from 'react';
import { Root } from './styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

type Ref = HTMLSelectElement;
type Props = React.HTMLProps<HTMLSelectElement>;

const Select = forwardRef<Ref, Props>(({ id, children, ...props }, ref) => {
  return (
    <Root>
      <select id={id} ref={ref} {...(props as any)}>
        {children}
      </select>

      <FontAwesomeIcon icon={faChevronDown} />
    </Root>
  );
});

export default Select;
