import styled from 'styled-components';
import { system } from '../../theme';

const Box = styled.div`
  max-width: 725px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 8px;
  background-color: ${system.colors('box.background')};
`;

export default Box;
