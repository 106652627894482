import React, { useEffect } from 'react';
import { Registration } from './view/Registration';
import { I18nProvider } from '@lingui/react';
import { activate, getDefaultLanguage, instance } from './i18n';
import { ConfigProvider, publisher } from './config';
import { Global, Head, ThemeProvider } from './publisher';

const App = () => {
  useEffect(() => {
    activate(getDefaultLanguage(), publisher);
  }, []);

  return (
    <React.StrictMode>
      <I18nProvider i18n={instance}>
        <ConfigProvider>
          <ThemeProvider>
            <Global />
            <Head />
            <Registration />
          </ThemeProvider>
        </ConfigProvider>
      </I18nProvider>
    </React.StrictMode>
  );
};

export default App;
